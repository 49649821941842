import React, { useState } from 'react';
import axios from 'axios';
import { Header } from '../Header';
import { Footer } from '../Footer';
import { useEffect } from "react";
import philips from '../../assests/philipbanner.png';
import philips1 from '../../assests/Group 1903.png';
import logo from '../../assests/lwror.jpeg';
import philipamazon from '../../assests/philipamazon.png';
import line from '../../assests/Group 1870 line2.png';
import philip1 from '../../assests/philips2000.png';
import philip2 from '../../assests/philip2.png';
import philip3 from '../../assests/philip3.png';
import philip4 from '../../assests/philip4.png';
import philip5 from '../../assests/philip5.png';
import philip6 from '../../assests/philip6.png';
import philip7 from '../../assests/philip7.png';
import philip8 from '../../assests/philip8.png';
import philip9 from '../../assests/philip9.png';
import philip10 from '../../assests/philip10.png';
import philip11 from '../../assests/philip11.png';
import philip12 from '../../assests/philip12.png';
import philip13 from '../../assests/philip13.png';
import { Link } from 'react-router-dom';

function Philip() {
  const [ipInfo, setIpInfo] = useState({});

  useEffect(() => {
    const fetchIpInfo = async () => {
      try {
        const response = await axios.get('https://ipinfo.io?token=f667c2c73c3553');
        setIpInfo(response.data);
      } catch (error) {
        console.error('Error fetching IP info:', error);
      }
    };

    fetchIpInfo();
  }, []);
  useEffect(() => {
    // Set the scroll position when the component mounts
    window.scrollTo(0, 0); // Adjust the '500' value as needed
  }, []);
  return (
    <>
      <Header />
      {/* section1 */}
      <section style={{ minHeight: "70vh" }}>
        <div className='container-fluid mt-5'>
          <div className='row mt-5 d-flex justify-content-center align-items-center mt-5'>
            <div className='col-md-5 mt-5 text-center '>
              <h2 className='text-dark sedanfont display-3  '>#PhilipsDream</h2>
              <h5 className='mt-3 sedanfont display-6  '>The Legacy Continues...</h5>
              {ipInfo.country == "IN" ?
                <Link to="/DonateIndia?reason=5" target="_top"> {/* Passing reason=5 as a URL parameter */}
                  <button class="btn btn-danger text-center mt-3">Donate in Honor Of Philip <i
                    class="fas fa-duotone fa-hand-holding-heart"></i> </button>
                </Link>
                : <Link to="#campaign-8483" target="_top">
                  <button class="btn btn-danger text-center mt-3">Donate in Honor Of Philip <i
                    class="fas fa-duotone fa-hand-holding-heart"></i> </button>
                </Link>}
            </div>

            <div className='col-md-5 mt-5'>
              <img className='img-fluid' src={philips} />
            </div>
          </div>
        </div>
      </section>


      {/* section2*/}
      <section style={{ minHeight: "100vh", backgroundColor: "#212129" }}>
        <div className='container '>
          <div className='row mt-5'>
            <div className='col-md-12 text-center mt-3'>
              {/* <h4 className='text-center text-white m-2 font-weight-bold'>GIVE IN HONOR OF PHILIP</h4> */}
              <h2 class=' text-white fw-bold mb-0 mt-3' >GIVE IN HONOR OF PHILIP</h2>
              <img className=" img-fluid" src={line} />
            </div>
          </div>
          <div className='row mt-3 aligh-items-center '>
            <div className='col-md-7 text-white text-justify'>
              <p className='mt-3' style={{ fontSize: "20px" }}>
                In a serious conversation with a media executive in India, then
                8-year-old Philip Mathews explained, "We have to help every child that we can because every child matters." October 2019, <span className='text-warning'>Love Without Reason</span> lost our dear and brilliant Philip Mathews. An inspiration, son, brother, and team member passed away.
              </p>

              <p className='mt-5' style={{ fontSize: "20px" }}>
                We are committed to continuing Philip's dream and vision.
                We were deeply honored that Philip invited those with the
                desire to make a change to contribute to <span className='text-warning'>Love Without Reason</span> in his memory. Gifts dedicated to the operation
                cost that supports Love Without Reason expansion
                will advance the work he cared so passionately for.
              </p>

              {ipInfo.country == "IN" ?
                 <Link to="/DonateIndia?reason=5" target="_top"> {/* Passing reason=5 as a URL parameter */}
                 <button class="btn btn-danger text-center mt-3">Give in Honor Of Philip <i
                   class="fas fa-duotone fa-hand-holding-heart"></i> </button>
               </Link>

                : <Link to="#campaign-8479" target='_top'><button class="btn btn-danger text-center mt-3">GIVE IN HONOR OF PHILIP <i
                  class="fas fa-duotone fa-hand-holding-heart"></i> </button></Link>
              }

              <div className='d-flex'>
                {/* <button className='btn text-white m-1' style={{ backgroundColor: "#ED1C24" }}>Donate Now</button>
                <button className='btn btn-outline-light m-1'>Read Philip's Story</button> */}
              </div>
            </div>
            <div className='col-md-5 mt-2'>
              <img src={philips1} className='img-fluid' />
            </div>
          </div>
        </div>
      </section>



      <section className='philip-timeline shadow' style={{ minHeight: '80vh' }}>
        <div className='container'>
          <div className='row d-flex justify-content-center'>
            <div className='col-md-12 mt-5 text-center'>
              <h2 class=' text-dark fw-bold mb-0' >PHILIP’S TIMELINE</h2>
              <img className=" img-fluid" src={line} />
            </div>
          </div>

          <div className='row d-flex justify-content-center'>
            <div className='col-md-10 mt-4 bg-dark rounded'>

              <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
                <div class="carousel-inner">
                  <div class="carousel-item active">
                    <div className='row d-flex justify-content-center p-5'>
                      <div className='col-md-5'>
                        <img className='img-fluid rounded' src={philip1} />
                      </div>
                      <div className='col-md-5 text-white'>
                        <h2 className='text-center fw-bold'>2000</h2>
                        <p className='india-text1'>
                          Philip’s birth on July 27, 2000, changed the lives of his parents, Susan and Santhosh. Due to heart failure and the continuous drop in his weight, Philip had his first surgery to place a gastric tube in his stomach for feedings every three hours
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="carousel-item">
                    <div className='row d-flex justify-content-center p-5'>
                      <div className='col-md-5'>
                        <img className='img-fluid rounded' src={philip2} />
                      </div>
                      <div className='col-md-5 text-white'>
                        <h2 className='text-center fw-bold'>2001</h2>
                        <p className='india-text1'>
                          Philip’s cleft lip and cleft palate were repaired this year. He continued with the tube feedings. The state of Tennessee also gave an opportunity for the Mathews family to enroll in the Family Training program at Siskin Children's Institute
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="carousel-item">
                    <div className='row d-flex justify-content-center p-5'>
                      <div className='col-md-5'>
                        <img className='img-fluid rounded' src={philip3} />
                      </div>
                      <div className='col-md-5 text-white'>
                        <h2 className='text-center fw-bold'>2002</h2>
                        <p className='india-text1'>
                          Philip continued to have various surgeries to reconstruct his facial features. He also transitioned into a classroom at Siskin Children’s Institute. With the help of the therapists, Philip began walking. He also transitioned from tube feedings to eating food by mouth. He progressed from sign language to speaking and communicating
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="carousel-item">
                    <div className='row d-flex justify-content-center p-5'>
                      <div className='col-md-5'>
                        <img className='img-fluid rounded' src={philip4} />
                      </div>
                      <div className='col-md-5 text-white'>
                        <h2 className='text-center fw-bold'>2003-2005</h2>
                        <p className='india-text1'>
                          Philip became a big brother to Sara and then Caleb, his only siblings. He continued to grow and improve in his verbal and social skills with their help
                        </p>
                      </div>
                    </div>
                  </div>


                  <div class="carousel-item">
                    <div className='row d-flex justify-content-center p-5'>
                      <div className='col-md-5'>
                        <img className='img-fluid rounded' src={philip5} />
                      </div>
                      <div className='col-md-5 text-white'>
                        <h2 className='text-center fw-bold'>2006-2007</h2>
                        <p className='india-text1'>
                          The state of Tennessee tested Philip and documented that he had no developmental delays. He was ready for kindergarten, transitioning into Westview Elementary School to begin his academic journey
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="carousel-item">
                    <div className='row d-flex justify-content-center p-5'>
                      <div className='col-md-5'>
                        <img className='img-fluid rounded' src={philip6} />
                      </div>
                      <div className='col-md-5 text-white'>
                        <h2 className='text-center fw-bold'>2008</h2>
                        <p className='india-text1'>
                          In August of 2008, Philip had his nasopharyngeal flap repair. At the time of extubation after his surgery, Philip’s airway closed. In this emergency situation, the medical team responded quickly and saved Philip’s life. He developed sleep apnea and started to wear a CPAP at bedtime
                        </p>
                      </div>
                    </div>
                  </div>



                  <div class="carousel-item">
                    <div className='row d-flex justify-content-center p-5'>
                      <div className='col-md-5'>
                        <img className='img-fluid rounded' src={philip7} />
                      </div>
                      <div className='col-md-5 text-white'>
                        <h2 className='text-center fw-bold'>2009-2010</h2>
                        <p className='india-text1'>
                          Philip’s birth on July 27, 2000, changed the lives of his parents, Susan and Santhosh. Due to heart failure and the continuous drop in his weight, Philip had his first surgery to place a gastric tube in his stomach for feedings every three hours
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="carousel-item">
                    <div className='row d-flex justify-content-center p-5'>
                      <div className='col-md-5'>
                        <img className='img-fluid rounded' src={philip8} />
                      </div>
                      <div className='col-md-5 text-white'>
                        <h2 className='text-center fw-bold'>2011-2012</h2>
                        <p className='india-text1'>
                          The renowned college preparatory McCallie School admission office told the Mathews family, “Philip doesn’t need McCallie, but McCallie needs Philip.” He began his journey with the Big Blue Brotherhood and found a place of acceptance with his peers.
                        </p>
                      </div>
                    </div>
                  </div>



                  <div class="carousel-item">
                    <div className='row d-flex justify-content-center p-5'>
                      <div className='col-md-5'>
                        <img className='img-fluid rounded' src={philip9} />
                      </div>
                      <div className='col-md-5 text-white'>
                        <h2 className='text-center fw-bold'>2013-2014</h2>
                        <p className='india-text1'>
                          Philip was told he would be tone deaf, due to his diminished hearing in only one ear. However, after taking one guitar class at McCallie and following training with his father, Philip began to play the guitar by ear. He continued to sing and play songs, many are found on YouTube.

                        </p>
                      </div>
                    </div>
                  </div>



                  <div class="carousel-item">
                    <div className='row d-flex justify-content-center p-5'>
                      <div className='col-md-5'>
                        <img className='img-fluid rounded' src={philip10} />
                      </div>
                      <div className='col-md-5 text-white'>
                        <h2 className='text-center fw-bold'>2015-2016</h2>
                        <p className='india-text1'>
                          Philip was not cleared for any contact sports by his medical team. However, he did not let that stop him from serving on the McCallie football team his four years in high school. He served with the coaches and film crew and continued working hard on his academics.

                        </p>
                      </div>
                    </div>
                  </div>


                  <div class="carousel-item">
                    <div className='row d-flex justify-content-center p-5'>
                      <div className='col-md-5'>
                        <img className='img-fluid rounded' src={philip11} />
                      </div>
                      <div className='col-md-5 text-white'>
                        <h2 className='text-center fw-bold'>2017</h2>
                        <p className='india-text1'>
                          Philip and his siblings joined Love Without Reason in the first medical camp to Africa – attending both the Zimbabwe and Kenya camps. He witnessed his first cleft surgery on an infant, and celebrated with the waiting parents.
                        </p>
                      </div>
                    </div>
                  </div>


                  <div class="carousel-item">
                    <div className='row d-flex justify-content-center p-5'>
                      <div className='col-md-5'>
                        <img className='img-fluid rounded' src={philip12} />
                      </div>
                      <div className='col-md-5 text-white'>
                        <h2 className='text-center fw-bold'>2018</h2>
                        <p className='india-text1'>
                          Philip led a humanitarian mission for Love Without Reason taking peers and faculty from the McCallie School to India. They attended a patient follow-up camp in Bangalore, visited the Project Butterfly Center in Mumbai and were able to bless the parents and children with hope, prayer and encouragement.
                        </p>
                      </div>
                    </div>
                  </div>


                  <div class="carousel-item">
                    <div className='row d-flex justify-content-center p-5'>
                      <div className='col-md-5'>
                        <img className='img-fluid rounded' src={philip13} />
                      </div>
                      <div className='col-md-5 text-white'>
                        <h2 className='text-center fw-bold'>2019</h2>
                        <p className='india-text1'>
                          May 19, 2019, Philip walked across the stage of the McCallie School and received his high school diploma. He was accepted into Lee University and decided to take a gap year. In June, he and Santhosh left for a trip to India, Dubai, and Zimbabwe to serve Love Without Reason. In July and August, he joined his siblings for his final medical camp into Zimbabwe. His prayer over the waiting patients, “I want you to do greater things than Philip ever did.” On October 9, he went through emergency surgery for a perforated ulcer. On October 26, 2019, Philip was laid to rest in Chattanooga, Tennessee
                        </p>
                      </div>
                    </div>
                  </div>


                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                  <span class="carousel-control-prev-icon btn btn-danger p-2 rounded" aria-hidden="true"></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                  <span class="carousel-control-next-icon btn btn-danger p-2 rounded" aria-hidden="true"></span>
                  <span class="visually-hidden">Next</span>
                </button>
              </div>
            </div>
          </div>
        </div>



      </section>


      {/* section4 */}
      <section className='bookphilip' style={{ minHeight: '70vh' }}>
        <div className='container'>
          <div className='row d-flex justify-content-center'>
            <div className='col-md-12 text-center mt-5'>
              <h2 class=' text-dark fw-bold mb-0'>AUTOBIOGRAPHY OF PHILIP MATHEWS</h2>
              <img className="img-fluid" src={line} />
            </div>
          </div>
          <div className='row d-flex justify-content-center'>
            <div className='col-md-6 '>
              <p className='mt-3 text-dark india-text' style={{ fontSize: "18px" }}>Philip Mathews lived nineteen years on earth. He was a typical teenager in everysense of the world but his very birth and life was anything but typical. After being born with a cleft palate, holes in his heart, absent right ear and eye, and missing parts of his brain, Philip Mathews was given little chance of survival. But he did survive and go on to live 19 years of purpose, becoming an international motivational speaker and advocate for the unborn and unseen spreading his message of hope across 48 states and 15 countries.</p>
              <p className='mt-5' style={{ fontSize: "18px" }}><i class="fa-solid fa-circle text-danger"></i> Typical Teenager</p>
              <p style={{ fontSize: "18px" }}><i class="fa-solid fa-circle text-danger"></i> Unseen Spreading</p>
              <p style={{ fontSize: "18px" }}><i class="fa-solid fa-circle text-danger"></i> Philip Mathews Autobiography</p>
              <p style={{ fontSize: "18px" }}><i class="fa-solid fa-circle text-danger"></i> Motivational Speaker</p>
            </div>
            <div className='col-md-4 mt-3 text-center'>
              <button className='btn btn-danger px-5 fw-bold'>
                <Link className="text text-white text-decoration-none" to="https://www.amazon.com/Philip-Can-You-Too-Surgeries-ebook/dp/B08P7ZXPZ6/ref=sr_1_1?crid=1J7G8NXWSCF5O&keywords=If+Philip+Can%2C+You+Can+Too%3A+How+I+Survived+Abortion%2C+24+Surgeries+and+High+School&qid=1667453542&qu=eyJxc2MiOiIwLjk3IiwicXNhIjoiMC4wMCIsInFzcCI6IjAuMDAifQ%3D%3D&sprefix=if+philip+can%2C+you+can+too+how+i+survived+abortion%2C+24+surgeries+and+high+school+%2Caps%2C369&sr=8-1" target='_blank'>Buy Now</Link></button>
              <Link to="https://www.amazon.com/Philip-Can-You-Too-Surgeries-ebook/dp/B08P7ZXPZ6/ref=sr_1_1?crid=1J7G8NXWSCF5O&keywords=If+Philip+Can%2C+You+Can+Too%3A+How+I+Survived+Abortion%2C+24+Surgeries+and+High+School&qid=1667453542&qu=eyJxc2MiOiIwLjk3IiwicXNhIjoiMC4wMCIsInFzcCI6IjAuMDAifQ%3D%3D&sprefix=if+philip+can%2C+you+can+too+how+i+survived+abortion%2C+24+surgeries+and+high+school+%2Caps%2C369&sr=8-1" target='_blank'>
                <img className='img-fluid' src={philipamazon} /></Link>
            </div>
          </div>
        </div>
      </section>

      {/* section5 */}
      <section className='mt-3 bg-light' style={{ minHeight: "100vh" }}>
        <div className='container'>
          <div className='row d-flex align-items-center'>
            <div className='col-md-12 mt-2 text-center '>
              {/* <h4 className='text-center text-white m-2 font-weight-bold'>PHILIPS VIDEOS</h4> */}
              <h2 class=' text-center text-dark fw-bold mb-0 mt-5' >PHILIPS VIDEOS</h2>
              <img className="text-center img-fluid" src={line} />
            </div>
          </div>
          <div className='row d-flex justify-content-center align-items-center mt-3'>
            <div class='col-md-8 mt-3'>
              <div class='card shadow border-0 text-center rounded'>
                <div className='row justify-content-center'>
                  <div className='col-3'>
                    <img className='img-fluid mt-2' src={logo} />

                  </div>
                </div>
                <iframe width="100%" height="315" src="https://www.youtube.com/embed/2D-G46CPvNo" title="Philip Mathews speaking at PCNAK Miami 2019" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen"
                  msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen"
                  webkitallowfullscreen="webkitallowfullscreen"></iframe>
                <div class="card-body border-0">
                  <h4 classname="text-dark font-weight-bold">PHILIP SPEAKING AT PCNAK</h4>
                </div>
              </div>
            </div>
          </div>
          <div className='row d-flex align-items-center'>
            <div className='col-md-12 mt-2 text-center '>
              {/* <h4 className='text-center text-white m-2 font-weight-bold'>PHILIPS VIDEOS</h4> */}
              <h2 class=' text-center text-dark fw-bold mb-0 mt-5' >PHILIP'S LIFE STORY</h2>
              <img className="text-center img-fluid" src={line} />
            </div>
          </div>
          <div className='row  d-flex justify-content-center mb-5'>
            <div class='col-md-4'>
              <div class='card shadow border-0 text-center rounded mt-3'>
                <iframe className='rounded' style={{ minHeight: '50vh', width: '100%' }} src="https://www.youtube.com/embed/AQldocL75bA?list=PLBZIyUuyNxr9sLBVD72H9vz1mLdHcYcLY" title="PHILIP MATHEWS - LIFESTORY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen"
                  msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen"
                  webkitallowfullscreen="webkitallowfullscreen"></iframe>
                <div class="card-body border-0">
                  <h4 classname="text-dark fw-bold">Philip’s<br /> Life Story</h4>
                </div>
              </div>
            </div>

            <div class='col-md-4'>
              <div class='card shadow border-0 text-center rounded mt-3'>
                <iframe style={{ minHeight: '50vh', width: '100%' }} src="https://www.youtube.com/embed/7Lxxv-tBsB8?list=PLBZIyUuyNxr9sLBVD72H9vz1mLdHcYcLY" title="700 Club Interactive  The Miracle of Life – December 10, 2015" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen"
                  msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen"
                  webkitallowfullscreen="webkitallowfullscreen"></iframe>
                <div class="card-body border-0">
                  <h4 classname="text-dark fw-bold">700 Club Interactive The Miracle of Life</h4>
                </div>
              </div>
            </div>

          </div>

          <div className='row d-flex align-items-center'>
            <div className='col-md-12 mt-2 text-center '>
              {/* <h4 className='text-center text-white m-2 font-weight-bold'>PHILIPS VIDEOS</h4> */}
              <h2 class=' text-center text-dark fw-bold mb-0 mt-2' >PHILIP'S SPEAKING</h2>
              <img className="text-center img-fluid" src={line} />
            </div>
          </div>
          <div className='row  d-flex justify-content-center mb-5 '>
            <div class='col-md-4'>
              <div class='card shadow border-0 text-center rounded mt-3'>
                <iframe style={{ minHeight: '50vh', width: '100%' }} src="https://www.youtube.com/embed/58DF_DwCeac?si=5X36RJsScDoACqjt" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen"
                  msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen"
                  webkitallowfullscreen="webkitallowfullscreen" ></iframe>
                <div class="card-body border-0">
                  <h4 classname="text-dark fw-bold"> Smile Creators- Featuring Philip Mathews</h4>
                </div>
              </div>
            </div>
            <div class='col-md-4'>
              <div class='card shadow border-0 text-center rounded mt-3'>
                <iframe style={{ minHeight: '50vh', width: '100%' }} src="https://www.youtube.com/embed/Te-dWyuc3mI?list=PLBZIyUuyNxr9sLBVD72H9vz1mLdHcYcLY" title="Love Without Reason, Philip Mathews - Motivational speaker" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen"
                  msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen"
                  webkitallowfullscreen="webkitallowfullscreen"></iframe>
                <div class="card-body border-0">
                  <h4 classname="text-dark fw-bold">Philip Mathews - Motivational speaker</h4>
                </div>
              </div>
            </div>
            <div class='col-md-4'>
              <div class='card shadow border-0 text-center rounded mt-3'>
                <iframe style={{ minHeight: '50vh', width: '100%' }} src="https://www.youtube.com/embed/PjEiHb49Djs?list=PLBZIyUuyNxr9sLBVD72H9vz1mLdHcYcLY" title="Philip - Never Give Up" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen"
                  msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen"
                  webkitallowfullscreen="webkitallowfullscreen"></iframe>
                <div class="card-body border-0">
                  <h4 classname="text-dark fw-bold">Philip <br /> Never Give Up</h4>
                </div>
              </div>
            </div>
          </div>
          <div className='row d-flex align-items-center'>
            <div className='col-md-12 mt-2 text-center '>
              {/* <h4 className='text-center text-white m-2 font-weight-bold'>PHILIPS VIDEOS</h4> */}
              <h2 class=' text-center text-dark fw-bold mb-0 mt-2' >PHILIP'S SINGING</h2>
              <img className="text-center img-fluid" src={line} />
            </div>
          </div>
          <div className='row  d-flex justify-content-center mb-5'>
            <div class='col-md-4'>
              <div class='card shadow border-0 text-center rounded mt-3'>
                <iframe style={{ minHeight: '50vh', width: '100%' }} src="https://www.youtube.com/embed/w_EoUHlYp7E?list=PLBZIyUuyNxr9sLBVD72H9vz1mLdHcYcLY" title="Philip at Westview&#39;s got Talent - Singing" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen"
                  msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen"
                  webkitallowfullscreen="webkitallowfullscreen"></iframe>
                <div class="card-body border-0">
                  <h4 classname="text-dark fw-bold">Philip at Westview's got Talent - Singing</h4>
                </div>
              </div>
            </div>
            <div class='col-md-4'>
              <div class='card shadow border-0 text-center rounded mt-3'>
                <iframe style={{ minHeight: '50vh', width: '100%' }} src="https://www.youtube.com/embed/RkzQtVJFNas?si=zSxDMs2z275wIjqn" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen"
                  msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen"
                  webkitallowfullscreen="webkitallowfullscreen"></iframe>
                <div class="card-body border-0">
                  <h4 classname="text-dark fw-bold">Philip on Mother's Day - Love Without Reason</h4>
                </div>
              </div>
            </div>
            <div class='col-md-4'>
              <div class='card shadow border-0 text-center rounded mt-3'>
                <iframe style={{ minHeight: '50vh', width: '100%' }} src="https://www.youtube.com/embed/FJ7YKxD_KvQ?list=PLBZIyUuyNxr9sLBVD72H9vz1mLdHcYcLY" title="Amazing grace - Philip Singing" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen"
                  msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen"
                  webkitallowfullscreen="webkitallowfullscreen"></iframe>
                <div class="card-body border-0">
                  <h4 classname="text-dark fw-bold">Amazing grace <br /> Philip Singing</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='mb-5' style={{ minHeight: "100vh", backgroundColor: " #1A1E27" }}>
        <div className="container ">
          <div className='row d-flex align-items-center' >
            <div className='col-md-12 mt-2 text-center '>
              {/* <h4 className='text-center text-white m-2 font-weight-bold'>PHILIPS VIDEOS</h4> */}
              <h2 class=' text-center text-white fw-bold mb-0 mt-5' >PHILIP'S HOME GOING</h2>
              <img className="text-center img-fluid" src={line} />
            </div>
          </div>
          <div className='row  d-flex justify-content-center mb-5 '>
            <div class='col-md-4'>
              <div class='card shadow border-0 text-center rounded mt-3'>
                <iframe className='rounded' style={{ minHeight: '50vh', width: '100%' }} src="https://www.youtube.com/embed/AQldocL75bA?list=PLBZIyUuyNxr9sLBVD72H9vz1mLdHcYcLY" title="PHILIP MATHEWS - LIFESTORY" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen"
                  msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen"
                  webkitallowfullscreen="webkitallowfullscreen"></iframe>
                <div class="card-body border-0">
                  <h4 classname="text-dark fw-bold">Philip’s <br />Life Story</h4>
                </div>
              </div>
            </div>
            <div class='col-md-4'>
              <div class='card shadow border-0 text-center rounded mt-3'>
                <iframe style={{ minHeight: '50vh', width: '100%' }} src="https://www.youtube.com/embed/oK3cl69rBJU?list=PLBZIyUuyNxr9sLBVD72H9vz1mLdHcYcLY" title="Philip Mathews  |  Memorial Service   |  10 - 25 -2019" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen"
                  msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen"
                  webkitallowfullscreen="webkitallowfullscreen"
                ></iframe>
                <div class="card-body border-0">
                  <h4 classname="text-dark fw-bold">Philip Mathews  Memorial Service  10 - 25 -2019</h4>
                </div>
              </div>
            </div>

            <div class='col-md-4'>
              <div class='card shadow border-0 text-center rounded mt-3'>
                <iframe style={{ minHeight: '50vh', width: '100%' }} src="https://www.youtube.com/embed/wvPOIuDRadY?list=PLBZIyUuyNxr9sLBVD72H9vz1mLdHcYcLY" title="Philip Mathews  |  Home Going  Service   |  10 - 26 -2019" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen="allowfullscreen"
                  mozallowfullscreen="mozallowfullscreen"
                  msallowfullscreen="msallowfullscreen"
                  oallowfullscreen="oallowfullscreen"
                  webkitallowfullscreen="webkitallowfullscreen"
                ></iframe>
                <div class="card-body border-0">
                  <h4 classname="text-dark fw-bold">Philip Mathews  Home Going Service 10 - 26 -2019</h4>
                </div>
              </div>
            </div>

          </div>

        </div>
      </section>


      <Footer />
    </>
  );
}

export { Philip };
